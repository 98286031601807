import React from 'react';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
    Route,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { ContactPage } from './Pages/ContactPage';
import { HowItWorksPage } from './Pages/HowItWorksPage';
import { ExamplesPage } from './Pages/ExamplesPage';
import { TutorialPage } from './Pages/TutorialPage';
import { OrderDetailPage } from './Pages/Orders/OrderDetailPage';
import { MyOrdersPage } from './Pages/Orders/MyOrdersPage';
import './Utils/AxiosSettings';
import { LoginPage } from './Pages/Account/LoginPage';
import { RegisterPage } from './Pages/Account/RegisterPage';
import { MyAccountPage } from './Pages/Account/MyAccountPage';
import { ForgottenPasswordPage } from './Pages/Account/ForgottenPasswordPage';
import { ChangePasswordPage } from './Pages/Account/ChangePasswordPage';
import { ErrorBoundary } from './Components/Others/ErrorBoundary';
import { GenerateArticlesPage } from './Pages/Generator/GenerateArticlesPage';
import { TransactionsPage } from './Pages/Account/TransactionsPage';
import { MyWebsitesPage } from './Pages/Websites/MyWebsitesPage';
import { WebsiteDetailPage } from './Pages/Websites/WebsiteDetailPage';
import { WebsiteWpExportPage } from './Pages/Websites/WebsiteWpExportPage';
import { TopUpPage } from './Pages/Account/TopUpPage';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ResetPasswordPage } from './Pages/Account/ResetPasswordPage';
import { FaqPage } from './Pages/FaqPage';
import { Homepage } from './Pages/Homepage';
import { MyArticlesPage } from './Pages/Articles/MyArticlesPage';
import { WebsiteArticlesConceptsPage } from './Pages/Websites/WebsiteArticlesConceptsPage';
import { EmailConfirmPage } from './Pages/Account/EmailConfirmPage';
import { GenerateProductsArticlesPage } from './Pages/Generator/GenerateProductsArticlesPage';
import { GenerateStoriesArticlesPage } from './Pages/Generator/GenerateStoriesArticlesPage';

const container = document.getElementById('root');
const root = createRoot(container!);

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://f358f5da6c1946c5b0afeb91fc0c8d46@o919004.ingest.sentry.io/4504729933053952",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        tracesSampleRate: 0.5,
        environment: process.env.NODE_ENV,
    });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter
);

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route path="/" errorElement={<ErrorBoundary />} element={<App />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/generate/articles" element={<GenerateArticlesPage />} />
            <Route path="/generate/products-articles" element={<GenerateProductsArticlesPage />} />
            <Route path="/generate/stories-articles" element={<GenerateStoriesArticlesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/examples" element={<ExamplesPage />} />
            <Route path="/tutorial" element={<TutorialPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/orders" element={<MyOrdersPage />} />
            <Route path="/orders/:id" element={<OrderDetailPage />} />
            <Route path="/articles" element={<MyArticlesPage />} />
            <Route path="/websites" element={<MyWebsitesPage />} />
            <Route path="/websites/:id" element={<WebsiteDetailPage />} />
            <Route path="/websites/:id/wp-export" element={<WebsiteWpExportPage />} />
            <Route path="/websites/:id/generate" element={<WebsiteArticlesConceptsPage />} />
            <Route path="/account/login" element={<LoginPage />} />    
            <Route path="/account/register" element={<RegisterPage />} />
            <Route path="/account/change-password" element={<ChangePasswordPage />} /> 
            <Route path="/account/forgotten-password" element={<ForgottenPasswordPage />} />
            <Route path="/account/reset-password/:code" element={<ResetPasswordPage />} />
            <Route path="/account/transactions" element={<TransactionsPage />} />
            <Route path="/account/top-up" element={<TopUpPage /> }/>
            <Route path="/account" element={<MyAccountPage />} />
            <Route path="/account/confirm-email/:email/token/:token" element={<EmailConfirmPage />} />          
        </Route>
    )
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);